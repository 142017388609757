import { render, staticRenderFns } from "./BadReceiptRegEnd.vue?vue&type=template&id=3e49f2b0&"
import script from "./BadReceiptRegEnd.vue?vue&type=script&lang=js&"
export * from "./BadReceiptRegEnd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports